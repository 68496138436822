import { Divider, Dropdown, MenuProps, Switch, theme } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { Content } from "antd/es/layout/layout";
import ProLayout, { ProSettings } from "@ant-design/pro-layout";
import defaultProps from "./_defaultProps";
import { useMSALInterceptor } from "../../Util/useMSALInterceptor";
import { CompanyContext } from "../../contexts/company-context";
import { useAccount, useMsal } from "@azure/msal-react";
import { logout } from "../../Util/logout";
import { useLocation } from "react-router";
import { DarkModeContext } from "../../contexts/theme-context";

import { ReactComponent as IconWhite } from "../../images/Icon_White.svg";

import { ReactComponent as IconDark } from "../../images/Icon_Dark.svg";
import { EmployeeContext } from "../../contexts/employee-context";

const CustomLayout = (): JSX.Element => {
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { updateSelectedCompany, companyData, selectedCompany } =
    useContext(CompanyContext);

  const { employeeData } = useContext(EmployeeContext);
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  useMSALInterceptor();
  const { toggleDarkMode, darkMode } = useContext(DarkModeContext);

  const [menuItem, setMenuItem] = useState<any>(defaultProps);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const handleLogout = () => logout(instance, account);

  const companyMenu: MenuProps["items"] = companyData.map((company) => {
    return {
      label: company.name,
      key: company.corporationId,
    };
  });

  const handleCompanyClick: MenuProps["onClick"] = ({ key }) => {
    updateSelectedCompany(parseInt(key));
  };

  const [settings] = useState<Partial<ProSettings> | undefined>({
    fixSiderbar: true,
    layout: "mix",
    splitMenus: false,
    colorPrimary: token.colorPrimary,
    title: "myTimeverse",
  });
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("selectedTab") || location.pathname
  );
  //const { pathname } = useLocation();
  //const selectedTab = localStorage.getItem('selectedTab') || '/';

  useEffect(() => {
    localStorage.setItem("selectedTab", activeTab);
  }, [activeTab]);

  /*useEffect(() => {
    const path = location.pathname;

    for (const item of menuItem) {
      if (path.startsWith(item.path)) {
        setActiveTab(item.path);
        break;
      }
    }
  }, [location.pathname, menuItem]);*/

  //ToDo: I need to add a check, if the UserInfo changes
  // I need to validate the Teams exists if the permision is
  // present.
  useMemo(() => {
    if (employeeData && !employeeData.permissions?.includes(-1)) {
      const settingsIndex = defaultProps.route.routes.findIndex(
        (x) => x.name === "Settings"
      );
      if (settingsIndex !== -1) {
        const settingsObj = defaultProps.route.routes[settingsIndex];

        const routes = settingsObj.routes;
        const teamIndex = routes?.findIndex((x) => x.name === "Team");
        const orgIndex = routes?.findIndex((x) => x.name === "Organization");

        // Collect indices to remove, filter out -1 values
        const indicesToRemove = [teamIndex, orgIndex]
          .filter((index) => index !== -1)
          .sort((a, b) => b! - a!);

        // Remove items at the specified indices
        indicesToRemove.forEach((index) => {
          routes?.splice(index!, 1);
        });

        // Update the state with the modified routes array
        setMenuItem(routes);
      }
    }
  }, [selectedCompany, userInfo, employeeData]);

  return (
    <div
      id="test-pro-layout"
      style={{
        height: "100vh",
      }}
    >
      <ProLayout
        {...settings}
        locale="en-US"
        //splitMenus
        /*token={{
          colorBgAppListIconHover: "rgba(0,0,0,0.06)",
          colorTextAppListIconHover: "rgba(255,255,255,0.95)",
          colorTextAppListIcon: "rgba(255,255,255,0.85)",
          sider: {
            //colorMenuBackground: token.colorPrimaryActive,
            colorBgCollapsedButton: "#fff",
            colorTextCollapsedButtonHover: "rgba(0,0,0,0.65)",
            colorTextCollapsedButton: "rgba(0,0,0,0.45)",
            colorMenuBackground: token.colorPrimary,
            colorBgMenuItemCollapsedHover: "rgba(0,0,0,0.06)",
            colorBgMenuItemCollapsedSelected: "rgba(0,0,0,0.15)",
            colorBgMenuItemCollapsedElevated: "rgba(0,0,0,0.85)",
            colorMenuItemDivider: "rgba(255,255,255,0.15)",
            colorBgMenuItemHover: "rgba(0,0,0,0.06)",
            colorBgMenuItemSelected: "rgba(0,0,0,0.15)",
            colorTextMenuSelected: "#fff",
            colorTextMenuItemHover: "rgba(255,255,255,0.75)",
            colorTextMenu: "rgba(255,255,255,0.75)",
            colorTextMenuSecondary: "rgba(255,255,255,0.65)",
            colorTextMenuTitle: "rgba(255,255,255,0.95)",
            colorTextMenuActive: "rgba(255,255,255,0.95)",
            colorTextSubMenuSelected: "#fff",
          },
        }}*/
        //title="MyTime System"
        logo={
          darkMode ? (
            <IconDark width={32} height={32} />
          ) : (
            <IconWhite width={32} height={32} />
          )
        }
        defaultSelectedKeys={["1"]}
        bgLayoutImgList={[
          {
            src: "https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png",
            left: 85,
            bottom: 100,
            height: "303px",
          },
          {
            src: "https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png",
            bottom: -68,
            right: -45,
            height: "303px",
          },
          {
            src: "https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png",
            bottom: 0,
            left: 0,
            width: "331px",
          },
        ]}
        {...defaultProps}
        location={{
          pathname: activeTab,
        }}
        menu={{
          collapsedShowGroupTitle: true,
          type: "sub",
        }}
        avatarProps={{
          src: "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
          size: "small",
          render: (props, dom) => {
            return (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "logout",
                      icon: <LogoutOutlined />,
                      label: "Logout",
                      onClick: handleLogout,
                    },
                  ],
                }}
              >
                {dom}
              </Dropdown>
            );
          },
          title: `${userInfo?.firstName} ${userInfo?.lastName}` /*(
            <span>
              <Dropdown trigger={["click", "hover"]} menu={{ items: userMenu }}>
                <p
                  style={{
                    marginTop: "15px",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  {`${userInfo?.firstName} ${userInfo?.lastName}`}
                </p>
              </Dropdown>
            </span>
          ),*/,
        }}
        actionsRender={(props) => {
          if (props.isMobile) return [];
          return [
            props.layout !== "side" && document.body.clientWidth > 1400 ? (
              <>
                <div
                  key="SearchOutlined"
                  aria-hidden
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginInlineEnd: 24,
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Dropdown.Button
                    menu={{
                      items: companyMenu,
                      onClick: handleCompanyClick,
                      selectedKeys: [
                        (
                          selectedCompany?.corporationId ??
                          companyData[0].corporationId
                        ).toString(),
                      ],
                    }}
                  >
                    {selectedCompany?.name}
                  </Dropdown.Button>
                </div>
                <Switch
                  checkedChildren="Dark"
                  unCheckedChildren="Light"
                  onChange={toggleDarkMode}
                />
              </>
            ) : undefined,
            //<InfoCircleFilled key="InfoCircleFilled" />,
            //<QuestionCircleFilled key="QuestionCircleFilled" />,
            //<GithubFilled key="GithubFilled" />,
          ];
        }}
        headerTitleRender={(logo, title, _) => {
          const defaultDom = (
            <a>
              {logo}
              {title}
            </a>
          );
          if (document.body.clientWidth < 1400) {
            return defaultDom;
          }
          if (_.isMobile) return defaultDom;
          return (
            <>
              {defaultDom}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    height: "1.5em",
                  }}
                  type="vertical"
                />
              </div>
            </>
          );
        }}
        menuFooterRender={(props) => {
          if (props?.collapsed) return undefined;
          return (
            <div
              style={{
                textAlign: "center",
                paddingBlockStart: 12,
              }}
            >
              <div>
                ©{new Date().getFullYear()} myTimeverse. All rights reserved.
              </div>
              icon by <a href="https://icons8.com">Icons8</a>
            </div>
          );
        }}
        //onMenuHeaderClick={(e) => console.log(e)}
        menuItemRender={(item, dom) => (
          <div
            onClick={() => {
              setActiveTab(item.path || "/home");
              //localStorage.setItem('selectedTab',item.path || "/home")
              navigate(`${item.path}`);
            }}
          >
            {dom}
          </div>
        )}
        {...settings}
      >
        <Content>
          <Outlet />
        </Content>
      </ProLayout>
    </div>
  );
};

export default CustomLayout;
