import React from "react";
import { EmployeeDetails } from "../serviceoperation/entity/EmployeeOperationEntity";

export interface IEmployeeContext {
  employeeData?: EmployeeDetails;
}

export const EmployeeContext = React.createContext<IEmployeeContext>({
  employeeData: undefined,
});
