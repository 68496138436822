import {
  ClockCircleOutlined,
  CoffeeOutlined,
  CloseCircleOutlined,
  PoweroffOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { Timeline, Empty, Button, Typography, theme } from "antd";
import { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/company-context";
import Unauthorized from "../../errors/unauthorized";
import { ScheduleDetails } from "../../serviceoperation/entity/ScheduleOperationEntity";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import ScheduleActivityModify from "./schedule-activity-modify";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { AuthenticationContext } from "../../contexts/authentication-context";

interface Values {
  employee: string;
}

interface ScheduleActivityProps {
  scheduleId: number;
  onUpdate: (values: Values) => void;
}

const ScheduleActivity: React.FC<ScheduleActivityProps> = ({ scheduleId }) => {
  const { Text, Title } = Typography;
  const { useToken } = theme;
  const { token } = useToken();
  const [OpenAddSchedule, setOpenAddSchedule] = useState(false);
  const [refreshScheduleDetail, setRefreshScheduleDetail] = useState(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const employeeOps = new EmployeeOperation();
  const { selectedCompany } = useContext(CompanyContext);
  const [scheduleDetail, setScheduleDetail] = useState<ScheduleDetails>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const scheduleOps = new ScheduleOperation();
    scheduleOps
      .getScheduleByIdAsync(
        selectedCompany?.corporationId as number,
        scheduleId
      )
      .then((x) => {
        //<Unauthorized displayHome={true} />;
        //console.log(x);
        setScheduleDetail(x);
      })
      .catch((error) => {
        <Unauthorized />;
      })
      .then(() => {
        setLoading(false);
      });
  }, [refreshScheduleDetail]);

  useEffect(() => {
    if (!scheduleDetail || !selectedCompany) {
      return;
    }

    const loggedInUserID = userInfo?.employeeCorporation?.find(
      (y) => y.corporationId === selectedCompany.corporationId
    )?.employeeId;

    if (scheduleDetail.employee.employeeID === loggedInUserID) {
      setIsManager(false);
      return;
    }

    const fetchEmployee = async () => {
      const employee = await employeeOps.getEmployeeByIdAsync(
        selectedCompany.corporationId,
        scheduleDetail.employee.employeeID
      );

      if (employee) {
        setIsManager(true);
      }
    };

    fetchEmployee();
  }, [scheduleDetail, selectedCompany, userInfo]);

  return (
    <>
      {scheduleDetail ? (
        <ScheduleActivityModify
          scheduleDetail={scheduleDetail!}
          open={OpenAddSchedule}
          onCreate={() => {
            setRefreshScheduleDetail(!refreshScheduleDetail);
            setOpenAddSchedule(false);
          }}
          onCancel={() => {
            setOpenAddSchedule(false);
          }}
        />
      ) : undefined}
      <ProCard
        loading={loading}
        extra={
          isManager ? (
            <EditOutlined
              style={{ color: token.colorPrimaryActive }}
              onClick={() => setOpenAddSchedule(true)}
            />
          ) : null
        }
      >
        <Title level={3} style={{ paddingLeft: "24px" }}>
          Schedule Activity
        </Title>
        <Timeline mode="left" style={{ padding: "24px", paddingTop: "0" }}>
          {scheduleDetail?.shiftActivity.map((item) => (
            <Timeline.Item
              key={item.scheduleActivityID}
              dot={(() => {
                switch (item.activityType) {
                  case "CLOCK_IN":
                    return <ClockCircleOutlined style={{ fontSize: "16px" }} />;
                  case "BREAK_START":
                    return <CoffeeOutlined style={{ fontSize: "16px" }} />;
                  case "BREAK_END":
                    return <CoffeeOutlined style={{ fontSize: "16px" }} />;
                  case "CLOCK_OUT":
                    return <CloseCircleOutlined style={{ fontSize: "16px" }} />;
                }
              })()}
            >
              {(() => {
                switch (item.activityType) {
                  case "CLOCK_IN":
                    return `Started Shift at: ${new Date(
                      item.activityDateTime + "Z"
                    ).toLocaleString()}`;
                  case "CLOCK_OUT":
                    return `Clocked out of shift at: ${new Date(
                      item.activityDateTime + "Z"
                    ).toLocaleString()}`;
                  case "BREAK_START":
                    return `Started Break at: ${new Date(
                      item.activityDateTime + "Z"
                    ).toLocaleString()}`;
                  case "BREAK_END":
                    return `Ended Break at at: ${new Date(
                      item.activityDateTime + "Z"
                    ).toLocaleString()}`;
                  default:
                    return null;
                }
              })()}
            </Timeline.Item>
          ))}
        </Timeline>
        {scheduleDetail?.shiftActivity === undefined ||
        scheduleDetail?.shiftActivity.length == 0 ? (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={
              <span>
                <Text>
                  Currently, there is no clock in activity available for this
                  shift. Either this shift has not started or shift is future
                  dated.
                </Text>
              </span>
            }
          >
            {isManager && new Date(scheduleDetail!.workDate) <= new Date() ? (
              <Button
                type="primary"
                style={{ marginBottom: 20 }}
                onClick={() => setOpenAddSchedule(true)}
              >
                Create Now
              </Button>
            ) : null}
          </Empty>
        ) : null}
      </ProCard>
    </>
  );
};

export default ScheduleActivity;
