import React, { useContext, useMemo, useState } from "react";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  theme,
} from "antd";
import { State, City } from "country-state-city";
import Paragraph from "antd/es/skeleton/Paragraph";
import { NewCorpEntity } from "./corp-new-entity";
import { TimeZoneOperation } from "../../serviceoperation/TimezoneOperation";
import { TimeZoneType } from "../schedule/modal/timeZone-types";
import { AuthenticationContext } from "../../contexts/authentication-context";

interface AddCorpInfoFormProps {
  newCorpData: NewCorpEntity;
  onCreate: (values: NewCorpEntity) => void;
}

const AddCorpBranchForm: React.FC<AddCorpInfoFormProps> = ({
  newCorpData,
  onCreate,
}) => {
  const [form] = Form.useForm();
  const { useToken } = theme;
  const { token } = useToken();
  const timeZoneOps = new TimeZoneOperation();
  const [timeZones, setTimeZone] = useState<TimeZoneType[]>([]);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  // I have to figure out how i can call TimeZone stuff once in the application.
  useMemo(() => {
    timeZoneOps.getTimeZoneAsync().then((value) => {
      setTimeZone(
        value.map((s) => ({
          key: s.id,
          name: s.id,
        }))
      );
    });
  }, [userInfo]);

  return (
    <div style={{ margin: "40px" }}>
      <Typography.Title level={5}>Create Branch</Typography.Title>
      <Typography.Paragraph>
        Let's take a moment to configur atleast one branch. A 'branch' in the
        context of our organization refers to one of the distinct offices,
        shops, or groups that belong to us, strategically positioned in
        different places. These branches serve as vital extensions of our main
        entity, allowing us to cater to a broader audience and enhance our
        services on a local level.
      </Typography.Paragraph>
      <Form
        //labelCol={{ span: 6 }}
        //wrapperCol={{ span: 18 }}
        form={form}
        name="dynamic_form_complex"
        //style={{ maxWidth: 600 }}
        autoComplete="off"
        layout="vertical"
        initialValues={newCorpData}
      >
        <Form.List name="branch">
          {(fields, { add, remove }) => (
            <div
              style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  //title={`Branch - ${field.name + 1}`}
                  key={field.key}
                  //style={{ border: "1px dashed" }}
                  extra={
                    <DeleteOutlined
                      style={{ color: token.colorError }}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <Row gutter={[10, 4]}>
                    <Col md={7} xs={24}>
                      <Form.Item
                        label="Name"
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                    </Col>
                    <Col md={7} xs={24}>
                      <Form.Item
                        label="Description"
                        name={[field.name, "description"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Description" />
                      </Form.Item>
                    </Col>
                    <Col md={14} xs={24}>
                      <Form.Item
                        name={[field.name, "timeZoneId"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a timezone!",
                          },
                        ]}
                        initialValue={timeZone}
                      >
                        <Select
                          placeholder="Select TimeZone"
                          defaultValue={timeZone}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={timeZones.map((tmz) => {
                            return {
                              value: tmz.key,
                              label: tmz.name,
                            };
                          })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Typography.Title level={5}>Address</Typography.Title>
                  <Typography.Paragraph>
                    Primary address of your corporation.
                  </Typography.Paragraph>

                  <Row gutter={[10, 4]}>
                    <Col lg={7} md={12} xs={24}>
                      <Form.Item
                        name={[field.name, "addressLine1"]}
                        label="Address"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={7} md={12} xs={24}>
                      <Form.Item
                        name={[field.name, "addressLine2"]}
                        label="Address Line 2"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    {/** Empty Spac*/}
                    <Col lg={7} md={12} xs={24}></Col>

                    <Col lg={5} md={12} xs={24}>
                      <Form.Item
                        name={[field.name, "city"]}
                        label="City"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={5} md={12} xs={24}>
                      <Form.Item
                        name={[field.name, "state"]}
                        label="State"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select State"
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={State.getStatesOfCountry("US").map(
                            (state) => {
                              return {
                                value: state.name,
                                label: state.name,
                              };
                            }
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={8} xs={24}>
                      <Form.Item
                        name={[field.name, "zip"]}
                        label="Zip"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Nest Form.List */}
                  <Form.Item label="Departments">
                    <Form.List name={[field.name, "departments"]}>
                      {(subFields, subOpt) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 16,
                          }}
                        >
                          {subFields.map((subField) => (
                            <Space key={subField.key}>
                              <Form.Item noStyle name={[subField.name, "name"]}>
                                <Input placeholder="Name" />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[subField.name, "description"]}
                              >
                                <Input placeholder="description" />
                              </Form.Item>
                              <CloseOutlined
                                onClick={() => {
                                  subOpt.remove(subField.name);
                                }}
                              />
                            </Space>
                          ))}
                          <Button
                            type="dashed"
                            onClick={() => subOpt.add()}
                            block
                          >
                            + Add Department
                          </Button>
                        </div>
                      )}
                    </Form.List>
                  </Form.Item>
                </Card>
              ))}

              <Button type="dashed" danger onClick={() => add()} block>
                + Add Branch
              </Button>
            </div>
          )}
        </Form.List>
        <Form.Item style={{ marginTop: "24px" }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form
                // Validate the required fields.
                .validateFields()
                .then(async (values) => {
                  const a = newCorpData;
                  a.branch = values.branch;
                  onCreate(a);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            Next
          </Button>
        </Form.Item>
        {/*<Form.Item noStyle shouldUpdate>
          {() => (
            <Typography>
              <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
            </Typography>
          )}
        </Form.Item>*/}
      </Form>
    </div>
  );
};

export default AddCorpBranchForm;
