import {
  Row,
  Col,
  Table,
  TableProps,
  Tag,
  Avatar,
  Select,
  DatePicker,
  DatePickerProps,
  Segmented,
  Button,
  FloatButton,
  theme,
  message,
  Space,
} from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { ColumnsType } from "antd/es/table";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import { EmployeeType, ScheduleType } from "./week-types";
import {
  AppstoreAddOutlined,
  CopyOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { CompanyContext } from "../../contexts/company-context";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import AddScheduleForm from "./modal/addSchedule";
import Legend from "../legend/legend";
import { timeOffStatus } from "../../constants";
import { EmployeeContext } from "../../contexts/employee-context";

const ScheduleWeek = (): JSX.Element => {
  // User and Company Context.
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { useToken } = theme;
  const { token } = useToken();
  const [OpenAddSchedule, setOpenAddSchedule] = useState(false);
  const [updateSchedule, SetupdateSchedule] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { employeeData } = useContext(EmployeeContext);

  const onCreate = (values: any) => {
    console.log("Received values of form: ", values);
    setOpenAddSchedule(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    SetupdateSchedule(!updateSchedule);

    //Show Alert successfully inserted schedule???
    message.success("Successfully processed your request.");
  };

  const currDate = new Date();

  // Selected Week Schedule Constants.
  const [selectedWeek, setSelectedWeek] = useState<string[]>([]);
  const [selectedWeekSchedule, setSelectedWeekSchedule] = useState<
    ScheduleType[]
  >([]);

  // This will be used for filtered results, so that we don't have to refetch
  // the data based on filter.
  const [filteredSchedule, setFilteredSchedule] = useState<ScheduleType[]>([]);

  // List of employee for a given logged in user.
  // This will contain a data regardless of who is logged in
  // as a logged in user will be in this list.
  const [employee, setEmployee] = useState<EmployeeType[]>([]);

  const [viewType, setviewType] = useState<string | number>("Week");
  const navigate = useNavigate();

  // Get the Current Week Dates based on any Date
  // This is to set the Table view Dynamically.
  // Make sure to add the offset of the
  const handleWeekChange = (selWeek: string) => {
    const current = new Date(selWeek);
    // So that this doesn't mess with the SetDate.
    current.setHours(0, 0, 0, 0);
    const week = [];
    for (let i = 0; i < 7; i++) {
      const first = current.getDate() - current.getDay() + i;
      const day = new Date(current.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }
    setSelectedWeek(week);
    // Process UseEffect call to get new Schedule... How to best do that?
  };

  useEffect(() => {
    handleWeekChange(currDate.toISOString());
  }, []);

  const handleBulkAddClick = () => {
    navigate(`/schedule/bulkadd`);
  };

  // Using this effect call to fetch the Employee List
  // However, should this be in the bottom useEffect and do promise ALL?
  // I don't want to block the execution of other things based ont his call
  // because i don't case if employee list does not populate.
  // Ask Dan how to best do this.
  const employeeOps = useMemo(() => new EmployeeOperation(), []);

  useEffect(() => {
    const getEmployee = async () => {
      if (!selectedCompany) return;
      const employees = await employeeOps.getEmployeeAsync(
        selectedCompany.corporationId,
        1,
        255
      );
      setEmployee(
        employees.results
          .filter((employee) => employee.isActive)
          .map((employee) => ({
            key: employee.employeeId,
            name: `${employee.firstName} ${employee.lastName}`,
          }))
      );
    };
    getEmployee();
  }, [selectedCompany, employeeOps]);

  useMemo(() => {
    // Get the EmployeeDetail from the ScheduleId
    // So that we can process the Manager Check Request....
    // Schedule User should be under a LoggedIn User to see
    // the schedule details.

    setIsManager(employeeData?.hasDirectReport ?? false);

    /*const employeeId = (userInfo?.employeeCorporation || []).find(
      (x) => x.corporationId === selectedCompany?.corporationId
    )?.employeeId;

    if (!employeeId) return;

    const employeeOps = new EmployeeOperation();
    employeeOps
      .getEmployeeByIdAsync(
        selectedCompany?.corporationId as number,
        employeeId
      )
      .then((x) => {
        setIsManager(employeeData?.hasDirectReport ?? false);
      });*/
  }, [userInfo, selectedCompany, employeeData]);

  useEffect(() => {
    setDataLoading(true);
    if (!selectedWeek || selectedWeek.length === 0) return;
    const scheduleOps = new ScheduleOperation();

    scheduleOps
      .getScheduleAsync(selectedCompany?.corporationId as number, {
        weekOf: selectedWeek[0],
        page: 1,
        pageSize: 255,
      })
      .then(({ results }) => {
        const groupedResult = groupBy(results, (p) => p.employee.employeeID);
        const employeeSchedule = Object.keys(groupedResult).map((key) => {
          const root: ScheduleType = {
            key: "",
            name: "",
            initial: "",
            days: Array.from({ length: 7 }, () => []),
          };

          groupedResult[key].forEach((x) => {
            const worktime = new Date(x.workDate);
            worktime.setHours(x.startWorkHour, x.startWorkMinute);
            const workStartTime = worktime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            worktime.setHours(x.endWorkHour, x.endWorkMinute);
            const workEndTime = worktime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });

            const workDay = new Date(x.workDate).getDay();

            root.key = x.employee.employeeID;
            root.name = `${x.employee.firstName} ${x.employee.lastName}`;
            root.initial = x.employee.firstName;
            root.days[workDay].push({
              key: x.scheduleID,
              time: `${workStartTime} - ${workEndTime}`,
              color:
                x.timeoffDetail &&
                x.timeoffDetail.timeOffStatusId === timeOffStatus.APPROVE
                  ? "red"
                  : x.timeoffDetail &&
                    x.timeoffDetail.timeOffStatusId === timeOffStatus.REQUESTED
                  ? "orange"
                  : "green",
            });
          });

          return root;
        });
        setSelectedWeekSchedule(employeeSchedule);
        setDataLoading(false);
      });
  }, [selectedWeek, updateSchedule, selectedCompany]);

  const columns: ColumnsType<ScheduleType> = [
    {
      title: "",
      dataIndex: "name",
      width: "16%",
      render(text, record) {
        return (
          <div>
            <Avatar
              style={{ backgroundColor: "#f56a00", verticalAlign: "middle" }}
              size="default"
              gap={4}
            >
              {record.initial}
            </Avatar>
            &nbsp;{text}
          </div>
        );
      },
    },
    {
      title: `${new Date(selectedWeek[0]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "sunday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[0].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[0].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: `${new Date(selectedWeek[1]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "monday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[1].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[1].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: `${new Date(selectedWeek[2]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "tuesday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[2].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[2].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: `${new Date(selectedWeek[3]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "wednesday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[3].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[3].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: `${new Date(selectedWeek[4]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "thursday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[4].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[4].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: `${new Date(selectedWeek[5]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "friday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[5].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[5].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
    {
      //title: `${new Date(curr.setDate(curr.getDate() - curr.getDay()+6)).toLocaleDateString('en-us', { timeZone:"UTC", weekday:"short", month:"short", day:"numeric"})}`,
      title: `${new Date(selectedWeek[6]).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "short",
        month: "short",
        day: "numeric",
      })}`,
      dataIndex: "saturday",
      align: "center",
      width: "12%",
      render: (_, { days }) => (
        <>
          {days[6].map((tag) => {
            return (
              <>
                <Link to={{ pathname: `/schedule/${tag.key}` }}>
                  <Tag color={tag.color} key={tag.key}>
                    {tag.time}
                  </Tag>
                </Link>
                {days[6].length > 0 ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : null}
              </>
            );
          })}
        </>
      ),
    },
  ];

  const onWeekChange: DatePickerProps["onChange"] = (date) => {
    // Have to set the Time of the day as well, otherwise it offsets the day by 1.
    //handleWeekChange(currDate.toDateString());
    handleWeekChange(date?.toISOString() as string);
  };

  return (
    <div>
      <div style={{ backgroundColor: token.colorBgContainer }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={18} style={{ padding: 20 }}>
            <Space size={6}>
              <DatePicker
                defaultValue={dayjs(currDate)}
                onChange={onWeekChange}
                picker="week"
              />

              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Filter by employee"
                optionFilterProp="children"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={employee.map((emp) => {
                  return {
                    value: emp.key,
                    label: emp.name,
                  };
                })}
              />
              <Space>
                <Segmented
                  options={["Week", "Month"]}
                  value={viewType}
                  onChange={setviewType}
                />
              </Space>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={0} lg={2}></Col>
          <Col xs={24} sm={24} md={6} lg={4} style={{ padding: 20 }}>
            <PrinterOutlined style={{ color: token.colorPrimaryActive }} />
            <CopyOutlined
              style={{ padding: 16, color: token.colorPrimaryActive }}
            />
            <Button
              type="primary"
              onClick={() => handleWeekChange(currDate.toISOString())}
            >
              Today
            </Button>
          </Col>
        </Row>
      </div>
      <Legend />
      <Row gutter={16} style={{ marginTop: "32px" }}>
        <Col span={24}>
          <Table
            style={{ whiteSpace: "pre" }}
            columns={columns}
            dataSource={selectedWeekSchedule}
            bordered
            loading={dataLoading}
          />
        </Col>
      </Row>
      {isManager ? (
        <>
          {/*<FloatButton
            icon={<PlusCircleOutlined />}
            type="primary"
            style={{ right: 24 }}
            onClick={() => {
              setOpenAddSchedule(true);
            }}
          />*/}
          <FloatButton.Group
            trigger="hover"
            type="primary"
            style={{ right: 24 }}
            icon={<PlusCircleOutlined />}
          >
            <FloatButton
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setOpenAddSchedule(true);
              }}
            />
            <FloatButton
              icon={<AppstoreAddOutlined />}
              onClick={handleBulkAddClick}
            />
          </FloatButton.Group>
          {OpenAddSchedule && (
            <AddScheduleForm
              open={OpenAddSchedule}
              onCreate={onCreate}
              onCancel={() => {
                setOpenAddSchedule(false);
              }}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

function handleChange(value: any) {
  console.log(`selected ${value}`);
}

function handleBlur() {
  console.log("blur");
}

function handleFocus() {
  console.log("focus");
}

function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

export default ScheduleWeek;
