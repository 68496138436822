import { ScheduleOutlined, FileDoneOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { PageContainer } from "@ant-design/pro-layout";
import { Menu, MenuProps } from "antd";
import { useContext, useMemo, useState } from "react";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import Company from "./company";
import Manager from "./manger";
import MyWeek from "./my-week";
import { EmployeeContext } from "../../contexts/employee-context";

const Dashboard = (): JSX.Element => {
  const defaultSelectedKey = "myWeek";
  const [currentTab, setCurrentTab] = useState<string>(defaultSelectedKey);
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const items: MenuItem[] = [
    //getItem("Company", "company", <DashboardOutlined />),
    getItem("My Week", "myWeek", <FileDoneOutlined />),
  ];
  const { employeeData } = useContext(EmployeeContext);

  const [menuItems, setMenuItems] = useState<MenuItem[]>(items);

  const handleTabChange = (event: any) => {
    setCurrentTab(event.key);
  };
  type MenuItem = Required<MenuProps>["items"][number];
  const renderComponent = () => {
    switch (currentTab) {
      case "company":
        return <Company />;
      case "manager":
        return <Manager />;
      default:
        return <MyWeek />;
    }
  };

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  useMemo(() => {
    // Get the EmployeeDetail from the ScheduleId
    // So that we can process the Manager Check Request....
    // Schedule User should be under a LoggedIn User to see
    // the schedule details.
    if (employeeData?.hasDirectReport) {
      setMenuItems([
        ...menuItems,
        getItem("Manager", "manager", <ScheduleOutlined />),
      ]);
    }
  }, [selectedCompany, userInfo, employeeData]);

  return (
    <PageContainer
      /*extra={[
        <Button key="1" type="primary">
          Main Operation
        </Button>,
      ]}*/
      subTitle="Unlock the power of data visualization with our comprehensive dashboard, providing you with an intuitive and insightful view of your weekly data."
      /*footer={[
        <Button key="3">Reset</Button>,
        <Button key="2" type="primary">
          Submit
        </Button>,
      ]}*/
    >
      <ProCard>
        <Menu
          onClick={handleTabChange}
          mode="horizontal"
          defaultSelectedKeys={[defaultSelectedKey]}
          selectedKeys={[currentTab]}
          items={menuItems}
        />
        {renderComponent()}
      </ProCard>
    </PageContainer>
  );
};

export default Dashboard;
