import { Row, Col, Table, Typography, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import {
  ConvertTo12hr,
  AmPMFormat,
  getFormattedDateTime,
} from "../../Util/dateutil";
import ScheduleQuickAction from "../schedule/schedule-quickaction";
import { WeekScheduleType } from "./my-week-types";
import { StatisticCard } from "@ant-design/pro-card";
import RcResizeObserver from "rc-resize-observer";
import {
  Employee,
  EmployeeActivity,
  ScheduleReportQuery,
} from "../../serviceoperation/entity/ScheduleOperationEntity";
import Notification from "./notification";

type ScheduleHistory = {
  employee: Employee;
  scheduleId: React.Key;
  title: string;
  note?: string;
  scheduleDate: string;
  startTime: string;
  endTime: string;
  employeeActivity?: EmployeeActivity;
};

const MyWeek = (): JSX.Element => {
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { selectedCompany } = useContext(CompanyContext);
  const [responsive, setResponsive] = useState(false);
  const [history, setHistory] = useState<ScheduleHistory[]>([]);
  const { Title, Paragraph } = Typography;
  const navigate = useNavigate();
  const scheduleOps = new ScheduleOperation();
  const [hoursWorked, setHoursWorked] = useState<number>(0);
  const [daysWorked, setDaysWorked] = useState<number>(0);
  const [forCastHours, setForCastHours] = useState<number>(0);

  const imgStyle = {
    display: "block",
    width: 42,
    height: 42,
  };

  const [weekSchedule, setWeekSchedule] = useState<WeekScheduleType[]>([]);

  const handleRowClick = (record: WeekScheduleType) => {
    navigate(`/schedule/${record.key}`);
  };

  useEffect(() => {
    const scheduleOps = new ScheduleOperation();
    scheduleOps
      .getScheduleAsync(selectedCompany?.corporationId as number, {
        weekOf: new Date().toLocaleDateString(),
        // This should never fail if a employee has made it this far.
        // their user context should have at least one item in the collection.
        employeeId: userInfo?.employeeCorporation
          ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
          .map((x) => x.employeeId)
          .at(0),
        page: 1,
        pageSize: 255,
      })
      .then((value) => {
        setWeekSchedule(
          value.results.map((s) => ({
            key: s.scheduleID,
            name: `${s.employee.firstName} ${s.employee.lastName}`,
            scheduleDate: `${new Date(s.workDate).toLocaleDateString("en-us", {
              timeZone: "UTC",
              weekday: "short",
              month: "short",
              day: "numeric",
            })}`,
            // Have Dan check this line, is this even efficent?
            scheduledTime: `${ConvertTo12hr(s.startWorkHour)}:${
              s.startWorkMinute === 0 ? "00" : s.startWorkMinute
            } ${AmPMFormat(s.startWorkHour)} - ${ConvertTo12hr(
              s.endWorkHour
            )}:${s.endWorkMinute === 0 ? "00" : s.endWorkMinute} ${AmPMFormat(
              s.endWorkHour
            )}`,
            totalHours: s.totalHours,
            location: "",
            notes: `${s.title}\n${s.note ? s.note : ""}`,
          }))
        );
      });
  }, [userInfo]);

  const fetchScheduleReport = async (options: ScheduleReportQuery) => {
    return await scheduleOps.getScheduleReportAsync(
      selectedCompany?.corporationId as number,
      options
    );
  };

  const fetchScheduleReportData = async (options: ScheduleReportQuery) => {
    return await fetchScheduleReport(options).then((response) =>
      response?.data?.results?.map((result) => ({
        employee: result.employee,
        scheduleId: result.scheduleId,
        title: result.title,
        note: result.note,
        scheduleDate: result.scheduleDate,
        startTime: getFormattedDateTime(result.employeeActivity?.clockedInAt),
        endTime: getFormattedDateTime(result.employeeActivity?.clockedOutAt),
        employeeActivity: result.employeeActivity,
      }))
    );
  };

  useEffect(() => {
    const options: ScheduleReportQuery = {
      weekOf: new Date().toDateString(),
      page: 1,
      pageSize: 255,
      IncludeDirReport: false,
    };
    fetchScheduleReportData(options).then(setHistory);
  }, [selectedCompany, userInfo]);

  useEffect(() => {
    if (history) {
      setHoursWorked(
        history
          .filter((item) => item.employeeActivity?.totalHoursWorked)
          .reduce(
            (sum, current) => sum + current.employeeActivity!.totalHoursWorked!,
            0
          )
      );

      setDaysWorked(
        history.filter((item) => item.employeeActivity?.clockedInAt).length
      );
    }

    if (weekSchedule) {
      setForCastHours(
        weekSchedule.reduce((sum, current) => sum + current.totalHours, 0)
      );
    }
  }, [history, weekSchedule]);

  const columns: ColumnsType<WeekScheduleType> = [
    {
      title: "Date Time",
      width: "20%",
      render: (record) => (
        <>
          {record.scheduleDate}
          <br />
          {record.scheduledTime}
        </>
      ),
      responsive: ["xs"],
    },
    {
      title: "Date",
      dataIndex: "scheduleDate",
      width: "20%",
      responsive: ["sm"],
      render: (text, record) => (
        <div>
          {new Date(record.scheduleDate).getDate() === new Date().getDate() ? (
            <div>
              {" "}
              <Tag
                color="green"
                style={{ margin: "-20px", marginTop: "-24px" }}
              >
                Today
              </Tag>{" "}
              <br />
            </div>
          ) : null}
          {text}
        </div>
      ),
    },
    {
      title: "Scheduled",
      dataIndex: "scheduledTime",
      width: "20%",
      responsive: ["sm"],
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: "30%",
      responsive: ["sm", "xs"],
    },
    {
      title: "Location",
      dataIndex: "location",
      width: "30%",
      responsive: ["sm"],
    },
  ];

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <div style={{ margin: "20px" }}>
        <Row gutter={16}>
          <Col xs={24} sm={18} md={18} lg={14} xl={12}>
            <Title level={2}>
              <>
                Welcome back, {userInfo?.firstName} {userInfo?.lastName}!
              </>
            </Title>
            <Paragraph>
              Here's what's happening across {selectedCompany?.name} today.
            </Paragraph>
          </Col>
        </Row>
        <StatisticCard.Group
          direction={responsive ? "column" : "row"}
          style={{ marginTop: 24 }}
        >
          <StatisticCard
            statistic={{
              title: "Forcasted Hours",
              value: forCastHours,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/external-glyph-on-circles-amoghdesign/48/external-forecast-weather-vol-02-glyph-on-circles-amoghdesign.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Hours Worked",
              value: hoursWorked,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/office/80/last-24-hours.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Days Worked",
              value: daysWorked,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/cotton/64/baby-calendar.png"
                  alt="icon"
                />
              ),
            }}
          />
        </StatisticCard.Group>
        <ScheduleQuickAction />
        <Row gutter={16} style={{ marginTop: "32px" }}>
          <Col xs={24} sm={18} md={18} lg={14} xl={12}>
            <Table
              style={{ whiteSpace: "pre", cursor: "pointer" }}
              columns={columns}
              pagination={false}
              dataSource={weekSchedule}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record),
                };
              }}
            />
          </Col>
          <Col xs={10} sm={8} md={8} lg={4} xl={2}></Col>
          <Col xs={24} sm={18} md={18} lg={12} xl={10}>
            <Notification manager={false} empl={false} />
          </Col>
        </Row>
      </div>
    </RcResizeObserver>
  );
};

export default MyWeek;
