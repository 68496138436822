import {
  EnvironmentOutlined,
  FileOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import {
  Space,
  Alert,
  Divider,
  Col,
  Row,
  Typography,
  Avatar,
  Button,
  Modal,
  theme,
  message,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import Unauthorized from "../../errors/internal-server";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { ScheduleDetails } from "../../serviceoperation/entity/ScheduleOperationEntity";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import { AmPMFormat, ConvertTo12hr } from "../../Util/dateutil";
import TimeOffRequestCancel from "./modal/timeoff-request-cancel";
import TimeOffModal from "./modal/timeoff-schedule-request";
import ScheduleQuickAction from "./schedule-quickaction";
import { timeOffStatus } from "../../constants";
import FindScheduleReplacement from "./modal/findScheduleReplacement";

interface Values {
  employee: string;
}

interface ScheduleSubDetailProps {
  scheduleId: number;
  onUpdate: (values: Values) => void;
}

const ScheduleSubDetail: React.FC<ScheduleSubDetailProps> = ({
  scheduleId,
}) => {
  const { selectedCompany } = useContext(CompanyContext);
  const { Text, Title } = Typography;
  const { useToken } = theme;
  const { token } = useToken();
  const [openTieOffModal, setOpenTieOffModal] = useState(false);
  const [openTimOffCancelModal, setOpenTimOffCancelModal] = useState(false);
  const [updateSchedule, SetupdateSchedule] = useState(false);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const [loading, setLoading] = useState<boolean>(false);

  const onCreateTimeOff = (values: any) => {
    setOpenTieOffModal(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    SetupdateSchedule(!updateSchedule);
    //Show Alert successfully inserted schedule???
    message.success("Successfully processed your request.");
  };

  const onCancelTimeOff = () => {
    setOpenTimOffCancelModal(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    SetupdateSchedule(!updateSchedule);
    //Show Alert successfully inserted schedule???
    message.success("Successfully processed your request.");
  };

  const onCreateReplacement = (success: boolean) => {
    setOpenFindReplacementModel(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    SetupdateSchedule(!updateSchedule);

    message.success("Successfully processed your request.");
  };

  // Following is set for TimeOff Request Model.
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  // Cancel Model
  const [cancelScheduleOpen, setCancelScheduleOpen] = useState(false);
  const [openFindReplacementModel, setOpenFindReplacementModel] =
    useState(false);
  const handleCancelScheduleOk = async () => {
    // Loading state for the Ok button...
    setConfirmLoading(true);
    const scheduleOps = new ScheduleOperation();
    await scheduleOps
      .deleteScheduleAsync(selectedCompany?.corporationId as number, scheduleId)
      .then((x) => {
        if (x.status === 204) {
          setCancelScheduleOpen(false);
          message.success("Successfully processed your request!");
          // On the schedule Cancel, navigate to Schedule Page.
          navigate(`/schedule`);
        } else if (x.status === 400) {
          message.error(
            "This schedule includes existing activities. Please make adjustments to the activities before proceeding with the deletion of this schedule."
          );
        } else {
          message.error("There was an error trying to process your request!");
        }
      })
      .catch((x) => {
        message.error("There was an error trying to process your request!");
      });
    setConfirmLoading(false);
  };

  const showCancleScheduleModal = () => {
    setCancelScheduleOpen(true);
  };

  const showFindReplacementModel = () => {
    setOpenFindReplacementModel(true);
  };
  const [scheduleDetail, setScheduleDetail] = useState<ScheduleDetails>();
  const [isManager, setIsManager] = useState<boolean>(false);
  const [showDetail, SetShowDetail] = useState<boolean>(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    setLoading(true);
    const scheduleOps = new ScheduleOperation();
    scheduleOps
      .getScheduleByIdAsync(
        selectedCompany?.corporationId as number,
        scheduleId
      )
      .then((x) => {
        //<Unauthorized displayHome={true} />;
        //console.log(x);
        setScheduleDetail(x);
      })
      .catch((error) => {
        <Unauthorized displayHome={true} />;
      })
      .then(() => {
        setLoading(false);
      });
  }, [updateSchedule]);

  useEffect(() => {
    const employeeOps = new EmployeeOperation();

    // Check if the scheduleDetail and selectedCompany exist
    if (scheduleDetail && selectedCompany) {
      // Get the employeeId of the currently logged in user
      const loggedInUserID = userInfo?.employeeCorporation?.find(
        (y) => y.corporationId === selectedCompany.corporationId
      )?.employeeId;

      // Check if the employeeId in the scheduleDetail is the same as the currently logged in user
      if (scheduleDetail.employee.employeeID === loggedInUserID) {
        SetShowDetail(true);
        setIsManager(false);
      } else {
        // Get the employee detail of the schedule user
        employeeOps
          .getEmployeeByIdAsync(
            selectedCompany.corporationId,
            scheduleDetail.employee.employeeID
          )
          .then((x) => {
            if (x) {
              SetShowDetail(true);
              setIsManager(true);
            } else {
              // ToDo: This never hits for some reasons.
              // Is it even going into the else clause?
              return <Unauthorized displayHome={true} />;
            }
          })
          .catch(() => <Unauthorized displayHome={true} />);
      }
    }
  }, [scheduleDetail, selectedCompany, userInfo]);

  return (
    <ProCard loading={loading}>
      {scheduleDetail?.timeoffDetail ? (
        <Space
          direction="vertical"
          style={{ width: "50%", marginBottom: "20px" }}
        >
          <Alert
            message={
              scheduleDetail.timeoffDetail.timeOffStatusId ===
              timeOffStatus.APPROVE
                ? "Note: Your time-off request for this schedule has been Approved by your manager."
                : scheduleDetail.timeoffDetail.timeOffStatusId ===
                  timeOffStatus.DENY
                ? "Note: Your time-off request for this schedule has been rejected by your manager."
                : scheduleDetail.timeoffDetail.timeOffStatusId ===
                  timeOffStatus.CANCEL
                ? "Note: Your time-off request for this schedule has been canceled."
                : "Note: Currently this request is pending time off approval. You may request to cancel your time off request using the option below."
            }
            type="info"
            showIcon
          />
        </Space>
      ) : null}
      {scheduleDetail?.replacementRequest ? (
        <Space
          direction="vertical"
          style={{ width: "50%", marginBottom: "20px" }}
        >
          <Alert
            message={
              "Note: Currently this schedule is pending swap request approval."
            }
            type="info"
            showIcon
          />
        </Space>
      ) : null}
      <br />
      {scheduleDetail && new Date(scheduleDetail.workDate) < today ? (
        <Space
          direction="vertical"
          style={{ width: "50%", marginBottom: "20px" }}
        >
          <Alert
            message="Note: This schedule has occurred in the past."
            type="warning"
            showIcon
          />
        </Space>
      ) : null}
      {scheduleDetail ? (
        <ScheduleQuickAction
          schedleInfo={{
            scheduleID: scheduleDetail.scheduleID,
            title: scheduleDetail.title,
            note: scheduleDetail.note,
            workDate: scheduleDetail.workDate,
            startWorkHour: scheduleDetail.startWorkHour,
            startWorkMinute: scheduleDetail.startWorkMinute,
            endWorkHour: scheduleDetail.endWorkHour,
            endWorkMinute: scheduleDetail.endWorkMinute,
            totalHours: 0,
            timeZoneId: scheduleDetail.timeZoneId,
            isHoliday: scheduleDetail.isHoliday,
            isWeekend: false,
            employee: scheduleDetail.employee,
            timeoffDetail: scheduleDetail.timeoffDetail,
          }}
        />
      ) : null}
      <br />
      <div style={{ backgroundColor: token.colorBgContainer, width: "80%" }}>
        <Space direction="vertical" style={{ width: "100%", padding: "24px" }}>
          <Row gutter={[16, 16]}>
            <Col span={1}>
              <Avatar
                size="small"
                icon={<UserOutlined style={{ color: token.colorPrimary }} />}
              />
            </Col>
            <Col span={23}>
              {" "}
              <Title level={5}>
                {scheduleDetail?.employee.firstName} {""}
                {scheduleDetail?.employee.lastName}
              </Title>
              <Text style={{ fontSize: "12px", marginTop: "10" }}>
                SomeEmail@gmail.com
              </Text>
              <br />
              <Text style={{ fontSize: "12px", marginTop: "10" }}>
                {scheduleDetail?.employee.title}
              </Text>
            </Col>
          </Row>
        </Space>
      </div>

      <div
        style={{
          //marginTop: "20px",
          backgroundColor: token.colorBgContainer,
          width: "80%",
        }}
      >
        <Space direction="vertical" style={{ width: "100%", padding: "24px" }}>
          <Row gutter={[16, 16]}>
            <Col span={1}>
              <InfoCircleOutlined style={{ color: token.colorPrimary }} />
            </Col>
            <Col span={23}>
              {" "}
              <Title level={5}>{scheduleDetail?.title}</Title>
              <Text style={{ fontSize: "12px" }}>
                {`${new Date(
                  scheduleDetail?.workDate ?? new Date()
                ).toLocaleDateString("en-us", {
                  timeZone: "UTC",
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}`}
              </Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                {ConvertTo12hr(scheduleDetail?.startWorkHour ?? 0)}:
                {scheduleDetail?.startWorkMinute === 0
                  ? "00"
                  : scheduleDetail?.startWorkMinute}{" "}
                {AmPMFormat(scheduleDetail?.startWorkHour ?? 0)}
                {" - "}
                {ConvertTo12hr(scheduleDetail?.endWorkHour ?? 0)}:
                {scheduleDetail?.endWorkMinute === 0
                  ? "00"
                  : scheduleDetail?.endWorkMinute}{" "}
                {AmPMFormat(scheduleDetail?.endWorkHour ?? 0)}
              </Text>
            </Col>
            <Col span={1}>
              <EnvironmentOutlined style={{ color: token.colorPrimary }} />
            </Col>
            <Col span={23}>
              {" "}
              <Title level={5}>{scheduleDetail?.branch.name}</Title>
              <Text style={{ fontSize: "12px" }}>
                {scheduleDetail?.branch.address.line1}
              </Text>
              <br />
              <Text style={{ fontSize: "12px" }}>
                {scheduleDetail?.branch.address.city},{" "}
                {scheduleDetail?.branch.address.state}{" "}
                {scheduleDetail?.branch.address.zip}
              </Text>
              <Divider />
            </Col>
            <Col span={1}>
              <FileOutlined style={{ color: token.colorPrimary }} />
            </Col>
            <Col span={23}>
              {" "}
              <Text style={{ fontSize: "14px" }}>{scheduleDetail?.note}</Text>
              <Divider />
            </Col>
            <Col span={1}></Col>
            {scheduleDetail && new Date(scheduleDetail.workDate) >= today ? (
              <Space direction="vertical" style={{ width: "30%" }}>
                <Col span={23}>
                  <>
                    {scheduleDetail?.timeoffDetail === undefined &&
                    scheduleDetail?.shiftActivity === undefined ? (
                      <>
                        <Button
                          block
                          onClick={() => {
                            setOpenTieOffModal(true);
                          }}
                          style={{
                            whiteSpace: "normal",
                            height: "auto",
                          }}
                        >
                          Request Shift Off
                        </Button>
                        <br />
                      </>
                    ) : scheduleDetail?.timeoffDetail !== undefined ? (
                      <>
                        <Button
                          block
                          danger
                          onClick={() => {
                            setOpenTimOffCancelModal(true);
                          }}
                          style={{
                            whiteSpace: "normal",
                            height: "auto",
                          }}
                        >
                          Cancel Time Off Request
                        </Button>
                        <br />
                      </>
                    ) : null}

                    {scheduleDetail?.replacementRequest ||
                    scheduleDetail?.shiftActivity !== undefined ? null : (
                      <Button
                        block
                        style={{
                          marginTop: "12px",
                          whiteSpace: "normal",
                          height: "auto",
                        }}
                        onClick={showFindReplacementModel}
                      >
                        Find Replacement
                      </Button>
                    )}

                    {isManager ? (
                      <Button
                        danger
                        onClick={showCancleScheduleModal}
                        block
                        style={{
                          marginTop: "12px",
                          whiteSpace: "normal",
                          height: "auto",
                        }}
                      >
                        Cancel Shift
                      </Button>
                    ) : null}
                  </>
                </Col>
              </Space>
            ) : null}
          </Row>
        </Space>
      </div>

      {openTieOffModal && scheduleDetail && (
        <TimeOffModal
          id={scheduleId}
          open={openTieOffModal}
          onCreate={onCreateTimeOff}
          onCancel={() => {
            setOpenTieOffModal(false);
          }}
        />
      )}

      {openTimOffCancelModal && scheduleDetail && (
        <TimeOffRequestCancel
          timeOffId={scheduleDetail?.timeoffDetail?.timeOffId ?? ""}
          open={openTimOffCancelModal}
          onCreate={onCancelTimeOff}
          onCancel={() => {
            setOpenTimOffCancelModal(false);
          }}
        />
      )}

      {openFindReplacementModel && scheduleDetail && (
        <FindScheduleReplacement
          open={openFindReplacementModel}
          schedule={scheduleDetail}
          onCreate={onCreateReplacement}
          onCancel={() => setOpenFindReplacementModel(false)}
        />
      )}

      <Modal
        title="Are you sure you want to cancel this shift?"
        open={cancelScheduleOpen}
        onOk={handleCancelScheduleOk}
        confirmLoading={confirmLoading}
        onCancel={() => setCancelScheduleOpen(false)}
      />
    </ProCard>
  );
};

export default ScheduleSubDetail;
