import React, {
  Children,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { EmployeeContext, IEmployeeContext } from "./employee-context";
import { EmployeeDetails } from "../serviceoperation/entity/EmployeeOperationEntity";
import { EmployeeOperation } from "../serviceoperation/EmployeeOperation";
import { CompanyContext } from "./company-context";
import { AuthenticationContext } from "./authentication-context";
import { useMSALInterceptor } from "../Util/useMSALInterceptor";
import LoadingState from "../components/configuration/loading-states";

interface EmployeeContextProviderProps {
  children: ReactNode;
  setLoading: (loading: LoadingState) => void;
}

const EmployeeContextProvider = (props: EmployeeContextProviderProps) => {
  // Remove this, and call the token directly in the service layer.
  useMSALInterceptor();

  const { children, setLoading } = props;
  const [employeeData, setEmployeeData] = useState<EmployeeDetails>();
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;

  useEffect(() => {
    const employeeOps = new EmployeeOperation();
    const employeeId = userInfo?.employeeCorporation?.find(
      (x) => x.corporationId === selectedCompany?.corporationId
    )?.employeeId;

    if (employeeId) {
      employeeOps
        .getEmployeeByIdAsync(
          selectedCompany?.corporationId as number,
          employeeId ?? ""
        )
        .then((x) => {
          setEmployeeData(x);
        })
        .catch(() => {
          setLoading(LoadingState.Error);
        });
    }
  }, [selectedCompany, userInfo]);

  const employeeContextValue: IEmployeeContext = {
    employeeData,
  };

  return (
    <EmployeeContext.Provider value={employeeContextValue}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContextProvider;
