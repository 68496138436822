import { Form, Input, Typography, Row, Button, Col, Select } from "antd";
import { useContext, useMemo, useState } from "react";
import { TimeZoneOperation } from "../../serviceoperation/TimezoneOperation";
import { State, City } from "country-state-city";
import { NewCorpEntity } from "./corp-new-entity";
import { TimeZoneType } from "../schedule/modal/timeZone-types";
import { userInfo } from "os";
import { AuthenticationContext } from "../../contexts/authentication-context";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

interface Values {
  employee: string;
  title: string;
  note: string;
  modifier: string;
  timeZone: string;
  location: string;
}

interface AddCorpInfoFormProps {
  newCorpData: NewCorpEntity;
  onCreate: (values: NewCorpEntity) => void;
}

//AddScheduleForm
const AddCorpInfoForm: React.FC<AddCorpInfoFormProps> = ({
  newCorpData,
  onCreate,
}) => {
  const [form] = Form.useForm();

  const timeZoneOps = new TimeZoneOperation();
  const [timeZones, setTimeZone] = useState<TimeZoneType[]>([]);
  const userInfo = useContext(AuthenticationContext)?.userInfo;

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  useMemo(() => {
    timeZoneOps.getTimeZoneAsync().then((value) => {
      setTimeZone(
        value.map((s) => ({
          key: s.id,
          name: s.id,
        }))
      );
    });
  }, [userInfo]);

  return (
    <Form
      layout="vertical"
      /*onFinish={onFinish}*/ style={{ margin: "40px" }}
      form={form}
      initialValues={newCorpData}
    >
      <Row gutter={[10, 4]}>
        <Col md={7} xs={24}>
          <Form.Item
            label="First Name"
            name={["contact", "firstName"]}
            rules={[
              {
                required: true,
                min: 1,
                max: 50,
              },
            ]}
          >
            <Input placeholder="First name" />
          </Form.Item>
        </Col>
        <Col md={7} xs={24}>
          <Form.Item
            label="Last Name"
            name={["contact", "lastName"]}
            rules={[
              {
                required: true,
                min: 1,
                max: 50,
              },
            ]}
          >
            <Input placeholder="Last name" />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title level={5}>Corporation Info</Typography.Title>
      <Typography.Paragraph>
        Please give us some basic info about your company? It'll help us serve
        you better down the road
      </Typography.Paragraph>

      <Row gutter={[10, 4]}>
        <Col md={14} xs={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                max: 50,
                min: 1,
              },
            ]}
          >
            <Input placeholder="Name of your corporation" />
          </Form.Item>
        </Col>
        <Col md={14} xs={24}>
          <Form.Item name="description" label="Description">
            <Input.TextArea
              showCount
              maxLength={255}
              placeholder="Tell us little about your corporation, what it does."
            />
          </Form.Item>
        </Col>
        {/* Empty Col so that i can bring Email in the next line. */}
        <Col md={7} xs={24}></Col>
        <Col md={7} xs={24}>
          <Form.Item name={["contact", "email"]} label="Email">
            <Input type="email" placeholder="Email where we can reach you!" />
          </Form.Item>
        </Col>
        <Col md={7} xs={24}>
          <Form.Item name="website" label="Website">
            <Input placeholder="Corporation website!" />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title level={5}>Address</Typography.Title>
      <Typography.Paragraph>
        Primary address of your corporation.
      </Typography.Paragraph>

      <Row gutter={[10, 4]}>
        <Col lg={7} md={12} xs={24}>
          <Form.Item
            name={["address", "addressLine1"]}
            label="Address"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={7} md={12} xs={24}>
          <Form.Item
            name={["address", "addressLine2"]}
            label="Address Line 2"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        {/** Empty Spac*/}
        <Col lg={7} md={12} xs={24}></Col>

        <Col lg={5} md={12} xs={24}>
          <Form.Item
            name={["address", "city"]}
            label="City"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={5} md={12} xs={24}>
          <Form.Item
            name={["address", "state"]}
            label="State"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select State"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={State.getStatesOfCountry("US").map((state) => {
                return {
                  value: state.name,
                  label: state.name,
                };
              })}
            />
          </Form.Item>
        </Col>
        <Col lg={4} md={8} xs={24}>
          <Form.Item
            name={["address", "zip"]}
            label="Zip"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={14} xs={24}>
          <Form.Item
            name="timeZoneId"
            rules={[
              {
                required: true,
                message: "Please select a timezone!",
              },
            ]}
            initialValue={timeZone}
          >
            <Select
              placeholder="Select TimeZone"
              defaultValue={timeZone}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={timeZones.map((tmz) => {
                return {
                  value: tmz.key,
                  label: tmz.name,
                };
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            form
              // Validate the required fields.
              .validateFields()
              .then(async (values) => {
                onCreate(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Next
        </Button>
      </Form.Item>
      {/*<Form.Item noStyle shouldUpdate>
        {() => (
          <Typography>
            <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
          </Typography>
        )}
      </Form.Item>*/}
    </Form>
  );
};

export default AddCorpInfoForm;
